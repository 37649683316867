<template>
  <ul class="pagination">
    <li
      v-if="pagination.current_page > 1"
      class="pagination-prev"
      href="javascript:void(0)"
      aria-label="Previous"
      v-on:click.prevent="changePage(pagination.current_page - 1)"
    >
      «Prev
    </li>
    <li
      v-for="page in pagesNumber"
      :key="page"
      :class="{ active: page == pagination.current_page }"
      v-on:click.prevent="changePage(page)"
    >
      {{ page }}
    </li>
    <li
      v-if="pagination.current_page < pagination.last_page"
      class="pagination-next"
      href="javascript:void(0)"
      aria-label="Next"
      v-on:click.prevent="changePage(pagination.current_page + 1)"
    >
      »Next
    </li>
  </ul>
</template>
<script>
import { computed } from "@vue/reactivity";
export default {
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    offset: {
      type: Number,
      default: 4,
    },
  },
  setup(props, context) {
    const pagesNumber = computed(function () {
      let currentPage = props.pagination.current_page;
      let firstPage = currentPage - 1;
      let secondPage = currentPage + 1;
      let lastPage = props.pagination.last_page;

      if (lastPage == 1) return [1];
      if ((currentPage == 1 || currentPage == 2) && lastPage == 2)
        return [1, lastPage];
      if (
        (currentPage == 1 || currentPage == 2 || currentPage == 3) &&
        lastPage == 3
      )
        return [1, 2, lastPage];
      if (lastPage == 4) return [1, lastPage - 2, lastPage - 1, lastPage];
      if (lastPage == 5)
        return [1, lastPage - 3, lastPage - 2, lastPage - 1, lastPage];
      if (currentPage == lastPage)
        return [1, "...", lastPage - 2, lastPage - 1, lastPage];
      if(currentPage == 2 && lastPage == 6)
      return [1 , currentPage , 3 , "..." , lastPage];
      // return [1];
      if (currentPage == lastPage - 1)
        return [1, "...", firstPage, currentPage, lastPage];
      // return [1, lastPage];

      if (currentPage == lastPage - 2)
        return [1, "...", firstPage, currentPage, secondPage, lastPage];
      // return [1, currentPage, secondPage, "...", lastPage];

      if (props.pagination.current_page >= props.pagination.last_page - 4)
        return [1, "...", firstPage, currentPage, secondPage, "...", lastPage];
      // return [currentPage, secondPage, "...", lastPage];

      if (
        props.pagination.current_page >= 4 &&
        props.pagination.current_page <= props.pagination.last_page - 4
      )
        return [1, "...", firstPage, currentPage, secondPage, "...", lastPage];

      if (currentPage == 1)
        return [currentPage, secondPage, secondPage + 1, "...", lastPage];
      if (currentPage == 2)
        return [firstPage, currentPage, secondPage, "...", lastPage];
      if (currentPage == 3)
        return [1, firstPage, currentPage, secondPage, "...", lastPage];
      return [1, currentPage, secondPage, "...", lastPage];
    });
    const changePage = (page) => {
      context.emit("paginate", page);
    };
    return {
      changePage,
      pagesNumber,
    };
  },
};
</script>
