<template>
  <Popup
    :header="
      props.mode == 'addUserOhada'
        ? $t('message.new_code')
        : $t('message.edit_code')
    "
  >
    <div class="form">
      <div class="clearfix"></div>
      <div v-if="form.isErrors" class="errors">
        <label class="error-text" v-for="(err, k) in form.errorSet" :key="k">{{
          err
        }}</label>
      </div>
      <div v-if="form.showCodeSameMsg" class="errors error-text">
        {{ $t("message.code_exist_msg") }}
      </div>
      <div class="grid gap d" style="--c: 1fr 2fr">
        <div class="form-group">
          <input
            type="text"
            class="form-input"
            v-focus
            :placeholder="$t('message.code')"
            v-model="form.code"
          />
          <label>{{ $t("message.code") }}</label>
        </div>

        <!-- <div class="form-group">
          <input
            type="text"
            class="form-input"
            v-focus
            :placeholder="$t('message.type')"
            v-model="form.type"
          />
          <label>{{ $t("message.type") }}</label>
        </div> -->
      </div>
      <br />
      <div class="grid">
        <div class="form-group">
          <input
            type="text"
            class="form-input"
            v-focus
            :placeholder="$t('message.description')"
            v-model="form.description"
          />
          <label>{{ $t("message.description") }}</label>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <button
        class="form-btn"
        v-if="!form.loading"
        @click="addEditUserOhadaCode"
      >
        {{ mode === "create" ? $t("message.create") : $t("message.update") }}
      </button>
      <button class="form-btn disabled" disabled v-if="form.loading">
        {{ mode === "create" ? "Saving..." : "Updating..." }}
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/Popup.vue";
import { onMounted, ref, computed } from "@vue/runtime-core";
import helper from "@/helper.js";
import { useStore } from "vuex";

import router from "@/router";
export default {
  components: { Popup },
  props: ["mode", "payload"],
  setup(props) {
    const store = useStore();
    const form = ref({
      loading: false,
      new_code: null,
      is_searching: false,
      showClientAddMsg: false,
      showCodeSameMsg: false,
      isErrors: null,
      errorSet: [],
    });
    const emitter = helper().emitter();
    var userOhadaCodes = computed(() => store.state.code.userOhadaCodes);

    function addEditUserOhadaCode() {
      form.value.errorSet = [];
      form.value.showCodeSameMsg = false;
      form.value.mode = props.mode;
      if (props.mode != "editUserOhada") {
        userOhadaCodes.value.map((code) => {
          if (code.code == form.value.code) {
            form.value.showCodeSameMsg = true;
          }
        });
      }
      if (form.value.showCodeSameMsg == false) {
        form.value.loading = true;
        store.dispatch("code/addEditUserOhadaCode", form.value).then((res) => {
          form.value.loading = false;
          if (res.status == "error") {
            form.value.isErrors = true;
            form.value.errorSet.push(res.message);
          }
          if (res.status == "success") {
            form.value.isErrors = false;
            store.commit("notification/SEND_NOTIFICATION", res);
            emitter.emit("close-popup");
            router.push({ name: "MyCodes" });
          }
          // form.value.showCodeSameMsg == false;
        });
      }
    }

    onMounted(async () => {
      // await store.dispatch("code/fetchUserOhadaCodes");
      emitter.emit("model-update");
      if (props.mode === "editUserOhada") {
        form.value = props.payload;
      }
    });

    return {
      form,
      addEditUserOhadaCode,
      props,
    };
  },
};
</script>
