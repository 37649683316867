<template>
  <div>
    <h3 class="heading">{{ $t("message.my_codes") }}</h3>
    <div class="grid d a-center" style="--c: 10fr 1fr">
      <div class="item">
        <div class="flex a-center sb">
          <div class="f-l">
            <div class="form-group">
              <input
                type="text"
                class="form-input"
                id="search_code"
                :placeholder="$t('message.search_code')"
                v-focus
                v-model="search_code"
              />
              <label for="serach_code">{{ $t("message.search_code") }}</label>
            </div>
          </div>
          <div class="f-r">
            <Dropdown
              @get="(val) => filterByClient(val)"
              :select_label="$t('message.filter_by_clients')"
              select_dd="client_dd"
              data_field="company_name"
              select_width="225px"
              :data="clients"
            ></Dropdown>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <CodeAddEditPopup
          v-if="popupTriggers.popup == 'code' && myCodes.length"
          :mode="popupTriggers.mode"
          :payload="popupTriggers.payload"
        ></CodeAddEditPopup>
        <table class="table" v-if="myCodes.length">
          <thead>
            <th>{{ $t("message.company_name") }}</th>
            <th>{{ $t("message.company_code") }}</th>
            <th class="text-center">{{ $t("message.actions") }}</th>
          </thead>
          <tbody>
            <tr v-for="(code, key) in myCodes" :key="key">
              <td>{{ code.company_name }}</td>
              <td>{{ code.company_code }}</td>
              <td class="text-center">
                <button
                  class="form-btn xs"
                  @click="togglePopup('code', 'edit', code)"
                >
                  {{ $t("message.edit") }}</button
                >&nbsp;
                <button class="form-btn xs danger" @click="deleteCode(code.id)">
                  {{ $t("message.delete") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <h4 class="lbl-gray__empty">The list is empty!</h4>
        </div>
      </div>
    </div>
  </div>
  <br />
  <hr />
  <br />
  <div>
    <h3 class="heading">{{ $t("message.ohada_codes") }}</h3>
    <div class="grid d a-center" style="--c: 10fr 1fr">
      <div class="item">
        <div class="flex a-center sb">
          <div class="f-l">
            <div class="form-group">
              <input
                type="text"
                class="form-input"
                id="search_code"
                :placeholder="$t('message.search_code')"
                v-focus
                @keyup="getUserOhadaCodes"
                v-model="search_ohada_code"
              />
              <label for="serach_code">{{ $t("message.search_code") }}</label>
            </div>
          </div>
          <div class="f-l">
            <div class="form-group">
              <button
                class="form-btn xs"
                @click="togglePopup('userOhadaCode', 'addUserOhada', code)"
              >
                {{ $t("message.add_ohada_code") }}
              </button>
            </div>
          </div>
          <!-- <div class="f-r">
            <Dropdown
              @get="(val) => filterByClient(val)"
              :select_label="$t('message.filter_by_clients')"
              select_dd="client_dd"
              data_field="company_name"
              select_width="225px"
              :data="clients"
            ></Dropdown>
          </div> -->
        </div>
        <div class="clearfix"></div>
        <br />
        <OhadaCodeAddEditPopup
          v-if="popupTriggers.popup == 'userOhadaCode' && userOhadaCodes.length"
          :mode="popupTriggers.mode"
          :payload="popupTriggers.payload"
        ></OhadaCodeAddEditPopup>
        <table class="table" v-if="userOhadaCodes.length">
          <thead>
            <th>{{ $t("message.code") }}</th>
            <th>{{ $t("message.description") }}</th>
            <th class="text-center">{{ $t("message.actions") }}</th>
          </thead>
          <tbody>
            <tr v-for="(code, key) in userOhadaCodes" :key="key">
              <td>{{ code.code }}</td>
              <td>{{ code.description }}</td>
              <td class="text-center">
                <!-- <button
                  class="form-btn xs"
                  @click="togglePopup('code', 'addToProhada', code)"
                >
                  {{ $t("message.add_to_my_code") }}
                </button> -->
                <button
                  class="form-btn xs"
                  @click="togglePopup('userOhadaCode', 'editUserOhada', code)"
                >
                  {{ $t("message.edit") }}
                </button>
                &nbsp;
                <button
                  class="form-btn xs danger"
                  @click="deleteUserOhadaCode(code.id)"
                >
                  {{ $t("message.delete") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <h4 class="lbl-gray__empty">The list is empty!</h4>
        </div>
        <div>
          <Pagination
            :pagination="state.ohadaCode"
            @paginate="getUserOhadaCodes"
            :offset="5"
            v-if="userOhadaCodes.length"
          ></Pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import Dropdown from "@/components/element/Dropdown.vue";
import OhadaCodeAddEditPopup from "./OhadaCodeAddEditPopup.vue";
import CodeAddEditPopup from "./CodeAddEditPopup.vue";
import Pagination from "@/components/Pagination.vue";
import helper from "@/helper";
export default {
  components: { Dropdown, CodeAddEditPopup, Pagination, OhadaCodeAddEditPopup },
  setup() {
    const store = useStore();
    const state = reactive({
      filter: {
        client_id: null,
      },
      ohadaCode: {
        total: 0,
        per_page: 5,
        from: 1,
        to: 0,
        current_page: 1,
        last_page: 0,
      },
      offset: 4,
    });

    const search_code = ref(null);
    const search_ohada_code = ref(null);

    const emitter = helper().emitter();

    const popupTriggers = ref({
      popup: null,
      mode: "create",
      payload: null,
    });

    const clients = computed(() => store.state.client.clients);

    var myCodes = computed(() =>
      store.state.code.myCodes.filter((code) =>
        search_code.value
          ? code.company_name
              .toLowerCase()
              .includes(search_code.value.toLowerCase())
          : state.filter?.client_id
          ? code.client_id == state.filter.client_id
          : code
      )
    );

    function filterByClient(val) {
      state.filter.client_id = val?.id;
    }
    //FOR FETCH OHADA CODE
    const getUserOhadaCodes = async (selectedPage) => {
      state.ohadaCode.current_page = selectedPage;
      const data = {
        isPaginate: true,
        page: state.ohadaCode.current_page,
        isSearch: true,
        searchTerm: search_ohada_code.value,
      };
      await store.dispatch("code/fetchUserOhadaCodes", data).then((res) => {
        state.ohadaCode.current_page = res.data.data.current_page;
        state.ohadaCode.total = res.data.data.total;
        state.ohadaCode.to = res.data.data.to;
        state.ohadaCode.from = res.data.data.from;
        state.ohadaCode.per_page = res.data.data.per_page;
        state.ohadaCode.last_page = res.data.data.last_page;
      });
    };
    var userOhadaCodes = computed(() => store.state.code.userOhadaCodes);

    const togglePopup = async (popup, mode, code) => {
      popupTriggers.value.mode = mode;
      popupTriggers.value.popup = popup;
      popupTriggers.value.payload = code;
      // console.log("popup trigers", popupTriggers);
    };

    const deleteCode = async (code_id) => {
      if (confirm("Are you sure you want to delete this code?"))
        store
          .dispatch("code/deleteCode", code_id)
          .then((res) => store.commit("notification/SEND_NOTIFICATION", res));
    };

    const deleteUserOhadaCode = async (code_id) => {
      if (confirm("Are you sure you want to delete this code?"))
        store
          .dispatch("code/deleteUserOhadaCode", code_id)
          .then((res) => store.commit("notification/SEND_NOTIFICATION", res));
    };

    onMounted(async () => {
      getUserOhadaCodes();
      emitter.on("close-popup", () => {
      getUserOhadaCodes();
        popupTriggers.value.popup = null;
        popupTriggers.value.mode = null;
      });
      // fetch all codes created by loggedin user
      if (!myCodes.value?.length) {
        await store.dispatch("code/fetchMyCodes");
      }
      if (!store.state.client?.clients?.length) {
        await store.dispatch("client/fetchAllClients");
      }
      store.commit("component/SET_LOADER", false);
    });

    return {
      myCodes,
      clients,
      search_code,
      search_ohada_code,
      togglePopup,
      deleteUserOhadaCode,
      deleteCode,
      filterByClient,
      popupTriggers,
      userOhadaCodes,
      getUserOhadaCodes,
      state,
      // searchOhadaCode,
    };
  },
};
</script>
